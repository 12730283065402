const scrollToTop = () => {
  const scrollToTopBanner = document.querySelector('.scroll-to-top-banner');

  if (scrollToTopBanner) {
    scrollToTopBanner.addEventListener('click', () => {
      window.scrollTo(0, 0);
      location.reload();
    });
  }
};

export default scrollToTop;
