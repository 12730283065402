import 'intersection-observer';

const imgLazyloadService = () => {
  const images = document.querySelectorAll('.front-page-img'); // Use querySelectorAll

  const interactSettings = {
    rootMargin: '0px 0px 300px 0px',
  };

  function onIntersection(imageEntites) {
    imageEntites.forEach((image) => {
      if (image.isIntersecting) {
        observer.unobserve(image.target);

        if (!image.target.srcset && !image.target.src) {
          image.target.srcset = image.target.dataset.srcset;
          image.target.src = image.target.dataset.src;
        }

        image.target.onload = () => {
          image.target.classList.remove('lazy');
          image.target.classList.add('loaded');
        };
      }
    });
  }

  const observer = new IntersectionObserver(onIntersection, interactSettings);

  images.forEach(image => { // Use forEach directly on the NodeList
    observer.observe(image);
  });
};

export default imgLazyloadService;