import { countdownService, imgLazyloadService } from '../services';
import { scrollToTop } from '../utils';
import '../scss/styles.scss';

/* global nrpFrontPageData jQuery */

(($, { articleData }) => {
  $(() => {
    scrollToTop();
    imgLazyloadService();

    if (articleData) {
      // check out for param in url frontPageLive
      const frontPageLive = window.location.search.includes('frontPageLive');
      if (frontPageLive) {
        return;
      }

      countdownService.initCountdowns(articleData);
    }
  });
})(jQuery, nrpFrontPageData);
