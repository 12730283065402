let intervals = [];
const countdownService = {
  initCountdowns: (articleData = [], rootElem = document) => {
    articleData.forEach((article) => {
      const { countdown } = article;

      if (countdown && countdown.expirationDate) {
        const expirationDate = new Date(countdown.expirationDate);
        const timestampNow = Date.now();

        if (timestampNow < expirationDate.getTime()) {
          initCountdown(countdown, article.id);
        }
      }
    });

    function setCountdown(countdownNumber, countdownElement) {
      if (!countdownElement) {
        return;
      }

      countdownElement.textContent = countdownNumber;
    }

    function initCountdown(countdown, articleId) {
      const expirationDate = new Date(countdown.expirationDate);

      const countdownElement = rootElem.querySelector(
        `.article-id-${articleId} .article-countdown`
      );
      const countdownContainer = rootElem.querySelector(
        `.article-id-${articleId} .countdown-container`
      );

      let countdownNumber = calculateCountdown(expirationDate);

      const elem = rootElem.querySelector(`.article-id-${articleId} .countdown`);
      if (elem) {
        elem.style.display = 'block';
      }
      setCountdown(countdownNumber, countdownElement);

      const countdownInterval = setInterval(() => {
        countdownNumber = calculateCountdown(expirationDate);

        if (countdownNumber) {
          setCountdown(countdownNumber, countdownElement);
        } else {
          countdownContainer.style.display = 'none';
          clearInterval(countdownInterval);
        }
      }, 1000);

      intervals.push(countdownInterval);
    }

    function getCountdownObject(difference) {
      var seconds = Math.floor(difference / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);

      return {
        h: hours,
        m: minutes,
        s: seconds
      };
    }

    function getCountdown(timeObject) {
      var { h, m, s } = timeObject;

      h %= 24;
      m %= 60;
      s %= 60;

      const hoursString = h ? (h >= 10 ? h : '0' + h) + ':' : '';
      const minutesString = m ? (m >= 10 ? m : '0' + m) + ':' : '';
      const secondsString = s >= 10 ? s : '0' + s;

      return `${hoursString}${minutesString}${secondsString}`;
    }

    function calculateCountdown(countdownDate) {
      const dateNow = new Date();
      const countdown = getCountdownObject(countdownDate.getTime() - dateNow.getTime());

      return countdownDate - dateNow > 0 ? getCountdown(countdown) : null;
    }
  },
  clearIntervals: () => {
    intervals.forEach((interval) => clearInterval(interval));
    intervals = [];
  }
};

export default countdownService;
