/* global jQuery */

const $ = jQuery;

const scrollToTop = () => {
  $('.scroll-to-top-banner').click(() => {
    window.scrollTo(0, 0);
    location.reload();
  });
};

export default scrollToTop;
