import 'intersection-observer';
/* globals jQuery*/

const imgLazyloadService = () => {
    let images = jQuery('.front-page-img');
    const interactSettings = {
        rootMargin: '0px 0px 300px 0px',
    };

    function onIntersection(imageEntites) {
        imageEntites.forEach((image) => {
            if (image.isIntersecting) {
                observer.unobserve(image.target);

                if (!image.target.srcset && !image.target.src) {
                    image.target.srcset = image.target.dataset.srcset;
                    image.target.src = image.target.dataset.src;
                }

                image.target.onload = () => {
                    image.target.classList.remove('lazy');
                    image.target.classList.add('loaded');
                };
            }
        });
    }
    let observer = new IntersectionObserver(onIntersection, interactSettings);
    images.each((index, image) => {
        observer.observe(image);
    });
};

export default imgLazyloadService;
